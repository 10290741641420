// Fonts
@import url('https://fonts.googleapis.com/css?family=Nunito');

// // Variables
// @import 'variables';
@import '~datatables.net-bs4/css/dataTables.bootstrap4.css';

// // Bootstrap
@import './multi-select.css';
@import '~jquery-toast-plugin/src/jquery.toast.css';

@import '~daterangepicker/daterangepicker.css';

@import '~select2';


.avatar {
    // background: #4e73df;
    border-radius: 50%;
    color: #fff;
    display: inline-block;
    font-size: 18px;
    font-weight: 300;
    margin: 0;
    position: relative;
    vertical-align: middle;
    line-height: 1.28;
    height: 45px;
    width: 45px
  }

  .avatar[data-initial]::before {
    color: currentColor;
    content: attr(data-initial);
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 1
  }

#CounterListTable th { font-size: 11.5px; }
#CounterListTable td { font-size: 10.5px; }

.oc_show{display:none;}
